import React, {useState} from 'react';
import styles from './App.module.css';
import Navigation from '../navigation/Navigation';
import Intro from '../intro/Intro';
import FeatureList from '../feature-list/FeatureList';
import ContentWrapper from '../content-wrapper/ContentWrapper';
import Title from '../title/Title';
import PromoBlock from '../promo-block/PromoBlock';
import PricingBlock from '../pricing-block/PricingBlock';
import Footer from '../footer/Footer';
import ModalWindow from '../modal-window/ModalWindow';
import ContactForm from '../contact-form/ContactForm';
import ContactModalFormContext from '../../context/ContactModalFormContext';
import {BrowserRouter} from 'react-router-dom';
import {useScrollByHashOnLoad} from '../../hooks/useScrollByHashOnLoad';

function App() {
    useScrollByHashOnLoad();

    const navigationLinks = [
        {name: 'Программа', href: '#about'},
        {name: 'Кому стоит попробовать', href: '#should-i-try'},
        {name: 'Зачем мне все это', href: '#why-do-i-need-this'},
        {name: 'Подарочные сертификаты', href: '#certificates'},
    ];

    const [contactWindowShown, setContactWindowShown] = useState(false);
    const [contactFormComment, setContactFormComment] = useState('');
    const [contactFormTitle, setContactFormTitle] = useState('');

    const showContactForm = (formComment = '', formTitle = '') => {
        setContactFormComment(formComment);
        setContactFormTitle(formTitle);
        setContactWindowShown(true);
    };

    return (
        <ContactModalFormContext.Provider value={showContactForm}>
            <BrowserRouter>
                <div className={styles.app}>
                    <Navigation navLinks={navigationLinks}/>
                    <Intro/>
                    <div className={`${styles.greyBg} ${styles.padded} ${styles.noTopPaddingMobile}`}>
                        <ContentWrapper  id='about'>
                            <Title style={{textAlign: 'center'}}>Из чего состоит сессия?</Title>
                            <FeatureList/>
                        </ContentWrapper>
                    </div>
                    <div className={`${styles.whiteBg} ${styles.padded}`}>
                        <ContentWrapper>
                            <PromoBlock/>
                        </ContentWrapper>
                    </div>
                    <div className={`${styles.whiteBg} ${styles.padded}`}>
                        <ContentWrapper id='certificates'>
                            <PricingBlock/>
                        </ContentWrapper>
                    </div>
                    <Footer navLinks={navigationLinks}/>
                    <ModalWindow shown={contactWindowShown} setShown={setContactWindowShown} title={contactFormTitle || 'Связаться'} >
                        <ContactForm comment={contactFormComment}/>
                    </ModalWindow>
                </div>
            </BrowserRouter>
        </ContactModalFormContext.Provider>
    );
}

export default App;
