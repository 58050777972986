import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/app/App';
import AOS from 'aos';
import 'aos/dist/aos.css';
import TagManager from 'react-gtm-module'

if (process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID){
    TagManager.initialize({
        gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID
    });
}

AOS.init();
window.addEventListener('load', function() {
    AOS.refresh();
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);